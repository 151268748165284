<template>
  <a-drawer width="55%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <div style="height: 20px;"></div>
    <a-descriptions title="" bordered :column="1">
      <a-descriptions-item label="Product Series Name">
        {{ recordDate.goodsName }}
      </a-descriptions-item>

      <a-descriptions-item label="Product Series Introduction">
        {{ recordDate.productSeriesIntroduction }}
      </a-descriptions-item>

      <a-descriptions-item label="Product Model #">
        {{recordDate.productModel}}
      </a-descriptions-item>

      <a-descriptions-item label="Variant Code #">
        {{ recordDate.variantCode }}
      </a-descriptions-item>

      <a-descriptions-item label="Product Type">
        {{ recordDate.productType | filterProductType}}
      </a-descriptions-item>

      <a-descriptions-item label="Business Type">
        {{ recordDate.businessTypeName }}
      </a-descriptions-item>

      <a-descriptions-item label="Available Compatibility">
        {{ recordDate.type | filterType}}
      </a-descriptions-item>

      <a-descriptions-item label="Available Tank Size" v-if="recordDate.productType === 0">
        {{ recordDate.tankSizeValue }}
      </a-descriptions-item>

      <a-descriptions-item label="Brand">
        {{recordDate.brandName }}
      </a-descriptions-item>

      <a-descriptions-item label="Brand Product Model #">
        {{recordDate.brandProductModel }}
      </a-descriptions-item>

      <a-descriptions-item label="Brand Product Name">
        {{ recordDate.brandMarkingName }}
      </a-descriptions-item>
    </a-descriptions>
  </a-drawer>
</template>

<script>
export default {
  name: "DetailsForm",
  data() {
    return {
      formTitle:"Product Info",
      open: false,
      recordDate:{}
    }
  },
  filters:{
    filterProductType(val) {
      if(val == 1) {
        return 'Batteries'
      }else if(val == 2) {
        return 'Vaporizer'
      } else {
        return 'Disposable'
      }
    },
    filterType(val) {
      if(val == 1) {
        return 'OMNI Hub'
      } else {
        return 'OMNI NFC'
      }
    }

  },
  methods: {
    onClose() {
      this.open = false;
    },
    show(record) {
      this.recordDate = record;
      this.open = true;
    }
  }
}
</script>

<style scoped>

</style>